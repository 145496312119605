import React, { useEffect, useMemo, useState } from "react"

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import TableContainer from "../Table/TableContainer"

import { Config } from '../../config';
import { useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { saveAs } from 'file-saver';

import {
    Form,
    Button,
    Container,
    Col,
    Row
} from "react-bootstrap";

async function fetchOrdersAsync() {
    const response = await fetch(Config.apiUrl + `/order/unsassigned`, // FIXME :( damn typo
        {
            method: 'GET',
            headers: AuthHeader()
        })

    return await HandleResponse(response);
}

function deleteOrder(id) {
    fetch(Config.apiUrl + '/order/' + id,
        {
            method: 'DELETE',
            headers: AuthHeader()
        }).then((response) => HandleResponse(response)).then(() => window.location.reload());
}

function createUnassignedOrders(productName, distributor, numberOfOrders) {
    let jsonString = JSON.stringify({ ProductName: productName, DistributorName: distributor, NumberOfOrders: numberOfOrders });
    return fetch(Config.apiUrl + '/order/unassigned/create/',
        {
            method: 'POST',
            headers: AuthHeader(),
            body: jsonString
        })
        .then(response => HandleResponse(response))
        .then(() => window.location.reload());
}

async function fetchProductsAsync() {
    const response = await fetch(Config.apiUrl + `/product/`);
    return await HandleResponse(response);
}


function getProductName(productData, id) {
    for (let i = 0; i < productData.length; i++) {
        if (productData[i].id === id) {
            return productData[i].name;
        }
    }

    return "unkown";
}

async function getBatchProductName(batchNumber) {
    // let jsonString = JSON.stringify({ BatchNumber: batchNumber });
    var response = await fetch(Config.apiUrl + '/order/unassigned/productname/' + batchNumber,
        {
            method: 'GET',
            type: 'text/plain;charset=utf-8',
            headers: AuthHeader()
        });

    return await HandleResponse(response);
}

async function getBatchSerials(batchNumber) {
    var productName = await getBatchProductName(batchNumber);
    return fetch(Config.apiUrl + '/order/unassigned/serials/' + batchNumber,
        {
            method: 'GET',
            type: 'text/plain;charset=utf-8',
            headers: AuthHeader()
        })
        .then(HandleResponse)
        .then(serialBlob => {
            var blob = new Blob([serialBlob.toString()], { type: "text/plain;charset=utf-8" });
            saveAs(blob, productName + ".txt")
        });
}

export default function AdminOrders() {
    // const { register: registerCreateOrders, handleSubmit: handleSubmitCreateOrders, formState: { errors: errorsCreateOrders } } = useForm();
    const { register: registerCreateOrders, handleSubmit: handleSubmitCreateOrders } = useForm();
    // const { register: registerDownload, handleSubmit: handleSubmitDownload, formState: { errors: errorsDownload } } = useForm();
    const { register: registerDownload, handleSubmit: handleSubmitDownload } = useForm();

    const params = useParams();
    const [productData, setProductData] = useState([])
    const [licenseData, setLicenseData] = useState([])

    useEffect(() => { // component did mount replacement
        const doFetch = async () => {
            const products = await fetchProductsAsync().catch(error => console.log("Error: (" + error + ")"));
            await setProductData(products);

            const licenses = await fetchOrdersAsync(String(params.id)).catch(error => console.log("Error: (" + error + ")"));

            // add product name row
            if (products !== undefined) {
                for (let i = 0; i < licenses.length; i++) {
                    licenses[i].productName = getProductName(products, licenses[i].productId);
                }
            }

            setLicenseData(licenses);
        }
        doFetch()
    }, [params.id])

    const licenseColumns = useMemo
        (
            () => [
                {
                    Header: "ID",
                    accessor: "id",
                    Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>,
                    width: 100
                },
                {
                    Header: "ProductName",
                    accessor: "productName",
                    minWidth: 200,
                    width: 200,
                    maxWidth: 200
                },
                {
                    Header: "ActivationKey",
                    accessor: "activationKey",
                    minWidth: 300
                },
                {
                    Header: "CreationDate",
                    accessor: "creationDate",
                    minWidth: 240,
                    maxWidth: 300,
                },
                {
                    Header: "Distributor",
                    accessor: "distributorName",
                    maxWidth: 160
                },
                {
                    Header: "Batch Nr",
                    accessor: "batchNumber",
                    maxWidth: 200
                },
                {
                    Header: "  ",
                    Cell: (props) => <Button variant="secondary" onClick=
                        {
                            () => deleteOrder(props.row.values.id)
                        }
                    >X</Button>,
                    minWidth: 50,
                    width: 50,
                    maxWidth: 50,
                    isResizing: false
                }
            ],
            []
        )

    return (
        <Container>
            <div className="newstitle">
                <div className="well">
                    <h2>Admin Orders</h2>
                </div>
            </div>
            <div className="well">
                <p>This generates registration keys for 3rd party distributors. The user can register on our page under (https://tal-software.com/activate/) after he bought a product.</p>
                <div>&nbsp;</div>
                <h5>Create new registration keys/orders for a distributor (PB or EXCH)</h5>
                <Form key={1} onSubmit={handleSubmitCreateOrders((data) => { createUnassignedOrders(data.productName, data.distributor, data.numberOfOrders) })}>
                    <Row>
                        <Col>
                            <Form.Label htmlFor="productName">Product</Form.Label><br />
                            <Form.Control as="select" id="productName" title="Select a product" {...registerCreateOrders("productName", { required: "Required" })}>
                                <option value="" defaultValue hidden>Choose product</option>
                                {
                                    productData.map(n => <option value={n.name} key={n.name}>{n.name}</option>)
                                }
                            </Form.Control>
                        </Col>

                        <Col>
                            <Form.Label htmlFor="distributor">Distributor</Form.Label><br />
                            <Form.Control placeholder="PB" id="distributor" {...registerCreateOrders("distributor", { required: "Required" })} type="text" /><br />
                        </Col>
                        <Col></Col>
                        <Col>
                            <Form.Label htmlFor="numberOfOrders">How many</Form.Label><br />
                            <Form.Control placeholder="100" id="numberOfOrders" {...registerCreateOrders("numberOfOrders", { required: "Required" })} type="number" /><br />
                        </Col>

                        <Col>
                            <Form.Label htmlFor="submit">&nbsp;</Form.Label><br />
                            <Button variant="primary" type="submit">CREATE</Button>
                        </Col>
                    </Row>
                </Form>
                <div>&nbsp;</div>
                <h5>Download Batch as File</h5>
                <Form key={3} onSubmit={handleSubmitDownload((data) => { getBatchSerials(data.batchNumber) })}>
                    <Row>
                        <Col>
                            <Form.Label htmlFor="batchNumber">Batch Number</Form.Label><br />
                            <Form.Control className="w-50" id="batchNumber" {...registerDownload("batchNumber", { required: "Required" })} type="number" /><br />
                        </Col>
                        <Col>
                            <Form.Label htmlFor="submit">&nbsp;</Form.Label><br />
                            <Button variant="primary" type="submit">DOWNLOAD</Button>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </Form>
                <div>&nbsp;</div>

                <h5>Unassigned licenses:</h5>
                <TableContainer columns={licenseColumns} data={licenseData} width="100%" />
                <div>&nbsp;</div>
            </div>
        </Container>
    );
}