import React from 'react';
import { useForm } from "react-hook-form";
import { Config } from '../config';
import { HandleResponse } from '../handleResponse';
import { useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
} from "react-bootstrap";

function sendSupportRequest(email, productName, operatingSystem, problemDescription) {
    let jsonString = JSON.stringify({ email, productName, operatingSystem, problemDescription });
    return fetch(Config.apiUrl + '/product/support/',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonString

        })
        .then(HandleResponse);
}

export default function SupportPage() {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        sendSupportRequest(data.email, data.productName, data.operatingSystem, data.problemDescription)
            .then(
                user => {
                    navigate("/messageview", { state: { message: "Support request sent." } });
                },
                error => {
                    setError("problemDescription", { message: "Error: Please try again later! " + error });
                }
            );
    }

    return (
        <div className="container">
            <div className="newstitle">
                <div className="well">
                    <h1>Support Request</h1>
                </div>
            </div>
            <div className="well">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Label htmlFor="email">Email:</Form.Label><br />
                    <Form.Control
                        id="email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /\S+@\S+\.\S+/, // email or admin
                                message: "Please enter a valid email address"
                            }
                        })}
                        type="text"
                    />
                    {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br />

                    <Form.Label htmlFor="productName">Product name:</Form.Label><br />
                    <Form.Control
                        id="productName"
                        {...register("productName", {
                            required: "required",
                            minLength: {
                                value: 4,
                                message: "Name too short"
                            }
                        })}
                        type="text"
                    />
                    {errors.productName && <Form.Text className="text-danger">{errors.productName.message}</Form.Text>}<br />

                    <Form.Label htmlFor="operatingSystem">Operating system and host software (including version):</Form.Label><br />
                    <Form.Control
                        id="operatingSystem"
                        {...register("operatingSystem", {
                            required: "required",
                            minLength: {
                                value: 4,
                                message: "Name too short"
                            }
                        })}
                        type="text"
                    />
                    {errors.operatingSystem && <Form.Text className="text-danger">{errors.operatingSystem.message}</Form.Text>}<br />

                    <Form.Label htmlFor="problemDescription">Problem description:</Form.Label><br />
                    <Form.Control as="textarea" rows={3}
                        id="problemDescription"
                        {...register("problemDescription", {
                            required: "required",
                            minLength: {
                                value: 20,
                                message: "Name too short"
                            }
                        })}
                        type="textarea"
                    />
                    {errors.problemDescription && <Form.Text className="text-danger">{errors.problemDescription.message}</Form.Text>}<br />

                    <Button variant="primary" type="submit">SEND</Button>
                    <div>&nbsp;</div>
                </Form>
            </div>
        </div>
    );
}