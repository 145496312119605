import { useShop } from "./ShopContext";
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Dropdown,
} from "react-bootstrap";

export default function ShopCartButton({ isMobile }) {
  const { data, cart } = useShop();
  const navigate = useNavigate();

  const checkout = () => {
    // Check if window object is defined before using it
    if (
      typeof window !== "undefined" &&
      window.fastspring &&
      window.fastspring.builder
    ) {
      // Push the payload to add the product
      window.fastspring.builder.checkout();
    }
  };

  // Redirects upon closing the popup
  window.onFSPopupClosed = function (orderReference) {
    if (window.fastspring && window.fastspring.builder) {
      window.fastspring.builder.reset();
    }

    // console.log(orderReference);
    if (orderReference && orderReference.id) {
      // navigate("/messageview", { state: { message: "Thanks for your purchase. Order Reference: " + orderReference.reference } });
      navigate("/messageview", { state: { message: "Thanks for your purchase. " } });
      // window.location.replace("purchase_success?orderId=" + orderReference.id);
    }
  };

  const removeItem = (path) => {
    // Check if window object is defined before using it
    if (
      typeof window !== "undefined" &&
      window.fastspring &&
      window.fastspring.builder
    ) {
      // Push the payload to add the product
      window.fastspring.builder.remove(path);
    }
  };

  return (
    <Dropdown className="d-inline mx-2" autoClose="outside" align={isMobile ? "start" : "end"} style={{ zindex: "20" }}>
      <Dropdown.Toggle className="showCartButton">
        <i className="material-icons" >shopping_cart</i>
        <span className="position-relative top-1 start-100 badge rounded-pill" style={{ color: "#FFFFFFBB", background: "#1c5480" }}>
          {
            cart.length
          }
          <span className="visually-hidden">prodcuts in cart</span></span>
        {

          /*
                <span className="ms-1" id="order-total">
                {
                  cart.length 
                }
              </span>
              */
        }

      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="cart-items-list" style={{ minWidth: "410px", margin: "10px" }}>
          {cart.length === 0 ? (
            <div style={{ textAlign: "center" }}>Your cart is empty</div>
          )
            :
            (
              cart.map(item => (
                <div key={item.path} className="cart-item row">
                  <div className="col" style={{ minWidth: "180px", whiteSpace: "nowrap" }}>
                    <span>{item.display}</span>
                  </div>
                  <div className="col" style={{ minWidth: "70px", whiteSpace: "nowrap", textAlign: "right" }}>
                    {item.discountPercentValue > 0 && (
                      <small><small><s>{item.priceValueWithoutTaxAndDiscounts.toFixed(2)}</s></small></small>
                    )}
                  </div>
                  <div className="col" style={{ minWidth: "90px", whiteSpace: "nowrap", textAlign: "right" }}>
                    {item.priceValueWithoutTax.toFixed(2)}
                  </div>
                  <div className="col" style={{ minWidth: "28px", padding: "0px"  }}>
                    {data.currency}
                  </div>
                  <div className="col">
                    <Button variant="none" className="cart-item-delete-button" style={{ padding: "0", offsetposition: "0px 10px" }} onClick={() => removeItem(item.path)}>X</Button>
                  </div>
                </div>
              ))
            )}
        </div>

        {cart.length > 0 && (
          <div style={{ margin: "10px" }}>
            {data.taxValue > 0 && ( /* only show tax when > 0 */
              <div className="cart-item row" style={{ color: "#AA8877FF" }}>
                <div className="col" style={{ minWidth: "250px" }}>
                  {data.taxRate + " " + data.taxType}
                </div>
                <div className="col" style={{ minWidth: "90px", textAlign: "right" }}>
                  {data.taxValue.toFixed(2)}
                </div>
                <div className="col" style={{ minWidth: "28px", padding: "0px"  }}>
                  {data.currency}
                </div>
                <div className="col" >
                </div>
              </div>
            )}
            <hr></hr>

            <div className="cart-item row" >
              <div className="col" style={{ minWidth: "250px" }}>
                <strong>Total</strong>
              </div>
              <div className="col" style={{ minWidth: "90px", textAlign: "right" }}>
                <strong>{data.totalValue.toFixed(2)}</strong>
              </div>
              <div className="col" style={{ minWidth: "28px", padding: "0px" }}>
                <strong>{data.currency}</strong>
              </div>
              <div className="col" >
              </div>
            </div>
            <hr></hr>
            <div textalign="right" style={{ minWidth: "100px", textAlign: "center" }}>
              <Button variant="primary" onClick={checkout}>Checkout</Button>
            </div>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};