import React from 'react';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

export default function LoginView() {
    return (
        <div className="container">
            <div className="newstitle">
                <div className="well text-center">
                    <h2>&nbsp;</h2>
                </div>
            </div>
            <div className="well">
                <div className="row content no-gutters">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3">
                        <div className="well_content">
                            <h3>Login</h3>
                            <LoginForm />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="well_content">
                            <h3>Register</h3>
                            <RegisterForm />
                        </div>
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                <div>&nbsp;</div>
            </div>
        </div>
    );
};