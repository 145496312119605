
import React, { useEffect, useMemo, useState } from "react"

import TableContainer from "../Table/TableContainer"

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';

import { useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
    Container,
    Col,
    Row
} from "react-bootstrap";

import { useForm } from "react-hook-form";

async function fetchPagesAsync() {
    const response = await fetch(Config.apiUrl + '/page/',
        {
            method: 'GET',
            headers: AuthHeader()
        })

    return await HandleResponse(response);
}

async function deleteAsync(id) {
    const response = await fetch(Config.apiUrl + '/page/' + id,
        {
            method: 'DELETE',
            headers: AuthHeader()
        })

    await HandleResponse(response);
    window.location.reload();
}

function editPage(id, navigate) {
    navigate('/admin/page/' + id, { replace: true });
}

function addPage(page) {
    let jsonString = JSON.stringify(page);
    fetch(Config.apiUrl + '/page/',
        {
            method: 'POST',
            headers: AuthHeader(),
            body: jsonString
        }).then((response) => HandleResponse(response)).then(() => window.location.reload());
}

export default function AdminPages() {
    const { register, handleSubmit } = useForm();

    const [data, setData] = useState([])
    const navigate = useNavigate();

    useEffect(() => { // component did mount replacement
        const doFetch = async () => {
            const pages = await fetchPagesAsync().catch(error => console.log("error: " + error));
            setData(pages)
        }
        doFetch()
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
                maxWidth: 80
            },
            {
                Header: "Url",
                accessor: "url",
                width: 380
            },
            {
                Header: "Name",
                accessor: "name",
                width: 200
            },
            {
                Header: "Main Menu",
                accessor: "isMainMenu",
                Cell: (props) => (props.row.original.isMainMenu ? 'true' : 'false'),
                width: 160
            },
            {
                Header: "Main Menu Priority",
                accessor: "mainMenuPriority",
                width: 200
            },
            {
                Header: "  ",
                Cell: (props) => <Button variant="secondary" onClick=
                    {
                        () => editPage(props.row.values.id, navigate)
                    }
                >EDIT</Button>,
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                isResizing: false
            },
            {
                Header: "   ",
                Cell: (props) => <Button variant="secondary" onClick=
                    {
                        () => deleteAsync(props.row.values.id)
                    }
                >X</Button>,
                minWidth: 50,
                width: 50,
                maxWidth: 50,
                isResizing: false
            }
        ],
        [navigate]
    )

    return <Container>
        <div className="newstitle">
            <div className="well">
                <h5>Edit Pages</h5>
            </div>
        </div>
        <div className="well">
            <h5>New page:</h5>
            <Form onSubmit={handleSubmit((data) => addPage({ id: -1, htmlText: "<h1>Hello World!!</h1>", url: data.url, mainMenuPriority: 0, isMainMenu: false }))}>
                <Row>
                    <Col>
                        <Form.Control placeholder="/test/test" id="url" {...register("url", { required: "Please enter a URL!" })}>
                        </Form.Control>
                    </Col>
                    <Col>
                        <Button variant="primary" type="submit">NEW PAGE</Button>
                    </Col>
                </Row>
            </Form>
            <div>&nbsp;</div>
            <h5>Pages:</h5>
            <TableContainer columns={columns} data={data} width="100%" />
            <div>&nbsp;</div>
        </div>
    </Container>;
}