import React, { createContext, useContext, useEffect, useState } from "react";

const ShopContext = createContext();

export const useShop = () => {
    return useContext(ShopContext);
};

export const ShopProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [data, setData] = useState({});
    const [cart, setCart] = useState([]);

    // Fills up products array when SBL loads
    useEffect(() => {
        const fastSpringCallBack = (data) => {
            setData(data);
            // console.log(data);
            if (data && data.groups) {
                const newProducts = data.groups
                    .filter((group) => group.items && Array.isArray(group.items))
                    .flatMap((group) => group.items);
                    setProducts(newProducts);
                    setCart(newProducts.filter((item) => item.selected));
                }
        };

        const addSBL = () => {
            const scriptId = "fsc-api";
            const existingScript = document.getElementById(scriptId);
            if (!existingScript) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.id = scriptId;
                script.setAttribute("data-continuous", "true");
                script.src = "https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js";
                script.dataset.storefront = "talsoftware.onfastspring.com/popup-talsoftware";
                // script.dataset.storefront = "talsoftware.test.onfastspring.com/popup-talsoftware";
                // script.dataset.storefront = "talsoftware.test.onfastspring.com";
                if (typeof window !== "undefined") 
                {
                    window.fastSpringCallBack = fastSpringCallBack;
                }
                script.setAttribute("data-data-callback", "fastSpringCallBack");
                script.setAttribute("data-popup-webhook-received", "onFSPopupClosed");

                document.body.appendChild(script);
            }
        };
        // Load SBL consistently
        addSBL();
    });

    return (
        <ShopContext.Provider value={{ products, data, cart }}>
            { children }
        </ShopContext.Provider>
    );
};

