import React, { useEffect } from 'react';
import './App.css';
import Navigation from './components/Navigation'
import { ShopProvider } from './components/Shop/ShopContext'

export default function App() 
{
  useEffect(() => 
  {
    /*
    <script src="https://cdn.paddle.com/paddle/v2/paddle.js"></script>
    <script type="text/javascript">
      Paddle.Environment.set("sandbox");
      Paddle.Setup({ 
        seller: 179528 // replace with your Paddle seller ID 
      });
    </script>
      */

    /*
    Paddle: 
  const addSBL = () => {
    const scriptId = "paddle";
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) 
    {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
      document.body.appendChild(script);
      script.addEventListener('load', () =>
       {
        const paddle = global.Paddle; // || null;
        paddle.Environment.set("sandbox");
        // paddle.Setup({ 15159, eventCallback });
        paddle.Setup({ seller: 15159 });
        setPaddle(paddle);
      });
    }
  };
  addSBL(); 
  */
  }, []); 


  return (
    <div>
      <ShopProvider>
        <Navigation/>
      </ShopProvider>
    </div>
  );
}

