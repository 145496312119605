
import React, { useEffect, useMemo, useState } from "react"

import TableContainer from "../Table/TableContainer"

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';

import { useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
    Container,
} from "react-bootstrap";

import { useForm } from "react-hook-form";

async function fetchProductsAsync() {
    const response = await fetch(Config.apiUrl + '/product/',
        {
            method: 'GET',
            headers: AuthHeader()
        })

    return await HandleResponse(response);
}

function deleteAsync(id) {
    fetch(Config.apiUrl + '/product/' + id,
        {
            method: 'DELETE',
            headers: AuthHeader()
        }).then((response) => HandleResponse(response)).then(() => window.location.reload());
}

function editProduct(id, navigate) {
    navigate('/admin/product/' + id, { replace: true });
}

async function addProduct(product) {
    let jsonString = JSON.stringify(product);
    fetch(Config.apiUrl + '/product/',
        {
            method: 'POST',
            headers: AuthHeader(),
            body: jsonString
        }).then((response) => HandleResponse(response)).then(() => window.location.reload());
}

export default function AdminProducts() {
    const { handleSubmit } = useForm();
    const [data, setData] = useState([])
    const navigate = useNavigate();

    useEffect(() => { // component did mount replacement
        const doFetch = async () => {
            const pages = await fetchProductsAsync().catch(error => console.log("error: " + error));
            setData(pages)
        }
        doFetch()
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
                maxWidth: 80
            },
            {
                Header: "Name",
                accessor: "name",
                width: 300
            },
            {
                Header: "Description",
                accessor: "description",
                width: 600
            },
            {
                Header: "  ",
                Cell: (props) => <Button variant="secondary" onClick=
                    {
                        () => editProduct(props.row.values.id, navigate)
                    }
                >EDIT</Button>,
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                isResizing: false
            },
            {
                Header: "   ",
                Cell: (props) => <Button variant="secondary" onClick=
                    {
                        () => deleteAsync(props.row.values.id)
                    }
                >X</Button>,
                minWidth: 50,
                width: 50,
                maxWidth: 50,
                isResizing: false
            }
        ],
        [navigate]
    )

    return <Container>
        <div className="newstitle">
            <div className="well">
                <h5>Products</h5>
            </div>
        </div>
        <div className="well">
            <Form onSubmit={handleSubmit((data) => addProduct({ id: 0, name: "New Name", description: "New Description", price: "0", publickey: "", privatekey: "" }))}>
                <Button variant="primary" type="submit">NEW PRODUCT</Button>
            </Form>
            <div>&nbsp;</div>
            <TableContainer columns={columns} data={data} width="100%" />
            <div>&nbsp;</div>
        </div>
    </Container>;
}