import React from 'react';
import { useLocation } from 'react-router-dom';

export default function MessageView() {
    const location = useLocation();

    return (
        <div className="container">
            <div className="newstitle">
                <div className="well">
                    <h2>Info</h2>
                </div>
            </div>
            <div className="well">
                <h4>{location.state?.message}</h4>
            </div>
        </div>
    );
};