import React from 'react';
import { useForm } from "react-hook-form";
import { Config } from '../../config';
import { HandleResponse } from '../../handleResponse';
import { useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
} from "react-bootstrap";

export default function LoginLostPassword() {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    function requestNewPassword(email) {
        let jsonString = JSON.stringify({ email });
        return fetch(Config.apiUrl + '/passwordrequest',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonString

            })
            .then(HandleResponse);
    }

    const onSubmit = (data) => {
        requestNewPassword(data.email)
            .then(
                user => {
                    navigate("/messageview", { state: { message: "An email with your new password has been sent to " + data.email } });
                },
                error => {
                    setError("email", { message: "Send serial failed: (" + error + ")" });
                }
            );
    }

    return (
        <div className="container">
            <div class="newstitle">
                <div class="well">
                    <h2>Request new password</h2>
                </div>
            </div>
            <div className="well">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Label htmlFor="email">Enter your email address:</Form.Label><br />
                    <Form.Control style={{width:"400px"}}
                        id="email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /\S+@\S+\.\S+/, // email or admin
                                message: "Please enter a valid email address"
                            }
                        })}
                        type="text"
                    />
                    {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br />
                    <div>&nbsp;</div>
                    <Button variant="primary" type="submit">SEND</Button>
                </Form>
            </div>
        </div>
    );
}