import React from 'react';

import { AuthenticationService } from '../../authenticationService';
import TransferLicenseForm from './TransferLicenseForm';
import { useParams } from "react-router-dom";

class TransferLicenseView extends React.Component
{
    constructor(props) {
        super(props);
        this.state = { 
            currentUser: AuthenticationService.currentUserValue,
            secretKey: '',
            orderId: this.props.params.id 
        };
    }

    render()
    {
        return <div className="container">
            <div className="well">
                <h1>Transfer License</h1>
                            <p>You can transfer a license to a new owner for 10$ + VAT. The license will be transferred as soon as our system receives the payment notification. This can take a few minutes.</p>
                            <h4>Email address of the new owner</h4>
                <TransferLicenseForm/>
            </div>
        </div>
    }
}

const TransferLicenseViewWithHooks = (props) => (<TransferLicenseView {...props} params={useParams()}/>);
export default TransferLicenseViewWithHooks;