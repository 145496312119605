import React from 'react';
import { Config } from '../config';
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useShop } from "./Shop/ShopContext";

class PageView extends React.Component
{
    constructor(props)
    {
        super(props);
        this.notFoundState = false;

        this.state = {
            page: {"date":"0001-01-01T00:00:00","htmlText":"<div className='loader' key='loader'/>","id":102,"isMainMenu":true,"mainMenuPriority":0,"name":"About","url":"/notExists"},
            visible: false
        };

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    fetchPage(urlIn) {
        urlIn = urlIn.replace(/\//g, "*");
        fetch(Config.apiUrl + "/page/byUrl/" + urlIn)
            .then(response => response.json())
            .then(data => 
                { 
                    data.htmlText = this.addShopPrice(data.htmlText);
                    this.setState({ page: data, visible: true });
                    // this.notFoundState = false; // keep error state, this is only for cashing
                }) 
            .catch(error => 
                {
                    this.notFoundState = true; // notfound tells prerender.io that the url was not valid
                    this.props.navigate("/", { replace: true });
                });
    }

    componentWillUnmount() 
    {
        document.removeEventListener('click', this.handleOnClick, false);
    }

    componentDidCatch(error, info) {
    }   

    componentDidMount()
    {
        this.componentDidUpdate();
    }

    componentDidUpdate(prevProps, prevState)
    { 
        const { page } = this.state;

        if (prevProps !== undefined && prevProps.products !== this.props.products)
        {
            // products loaded, we can add prices --> only required for the first load
            page.htmlText = this.addShopPrice(page.htmlText);
            this.setState({ page: page, visible: true });
        }

        var separator = '/';
        var currentPathName = separator.concat(this.props.params.pathParam1 ?? '')
                                        .concat(this.props.params.pathParam2 === undefined ? '' : separator.concat(this.props.params.pathParam2 ?? ''))
                                        .concat(this.props.params.pathParam3 === undefined ? '' : separator.concat(this.props.params.pathParam3 ?? ''));

        if (currentPathName.toLowerCase() === page.url.toLowerCase()) 
        {
            return;
        }

        page.url = currentPathName;
        this.fetchPage(currentPathName);
        this.setState({ page: this.state.page, visible: false, notfound: this.state.notfound });
    }

    render()
    {
        const { page } = this.state;

        return (
                <div>
                <Helmet>
                    <meta property="og:title" content={ this.getFirstTag(page.htmlText, "h1") } />
                    <meta property="og:description" content={ "VST3, Audio Unit, AAX, CLAP audio plug-ins for macOS, Windows and Linux" } />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={ window.location.href } /> 
                    <meta name="og:site_name" content={ page.name } />
                    <meta property="og:image" content={ this.getFirsImageUrl(page.htmlText) } />
                    <meta property="og:image:secure_url" content={ this.getFirsImageUrl(page.htmlText) } />
                    { this.notFoundState ? <meta name="prerender-status-code" content="404" />  : null }
                </Helmet>
                <div className="container">
                <div onClick={this.handleOnClick} dangerouslySetInnerHTML={{ __html: page.htmlText }}></div>
                </div>
                </div>
            );
    }

    handleOnClick(e) 
    {
        if (e.metaKey || e.ctrlKey || e.shiftKey) 
        {
            return;
        }

        if (e.target.type === "submit" && this.handleBuyNowButtonClick(e))
        {
            return;
        }

        // Get the <a> element.
        var el = e.target;
        while (el && el.nodeName !== 'A') {
            el = el.parentNode;
        }

        // Ignore clicks from non-a elements.
        if (!el) {
            return;
        }

        // Ignore the click if the element has a target.
        if (el.target && el.target !== '_self') {
            return;
        }

        // Ignore the click if it's a download link. (We use this method of
        // detecting the presence of the attribute for old IE versions.)
        if (el.attributes.download) {
            return;
        }

        let href = (String)(el.getAttribute('href'));
        // Ignore hash (used often instead of javascript:void(0) in strict CSP envs)
        if (href === '#') {
            return;
        }

        if (href.indexOf("/") !== 0) {
            return;
        }
        e.preventDefault();

        this.props.navigate(href, { replace: true });
    }

    getFirsImageUrl(str)
    {
        var searchRegex = "<img[^]*?src=\"(.*?)\"";
        var seReg = new RegExp(searchRegex, "i");
        var result = str.match(seReg);

        if (result == null)
        {
            return "";
        }

        var urlAbsolute = new URL(result[1], window.location.href).href;
        return urlAbsolute;
    }

    getFirstTag(str, tagName)
    {
        var searchRegex = "<" + tagName + ">(.*?)</" + tagName + ">";
        var seReg = new RegExp(searchRegex, "i");
        var result = str.match(seReg);

        if (result == null)
        {
            return "TAL Software GmbH";
        }

        return result[1];
    }

    addShopPrice(rawHtmlString)
    {
        if (window.fastspring === undefined || this.props.products === undefined)
        {
            return rawHtmlString;
        }

        // <ShopPrice product="tal-pha"/>

        // Use DOMParser to parse the raw HTML string into a DOM object
        const parser = new DOMParser();
        const doc = parser.parseFromString(rawHtmlString, 'text/html');
        const elementsWithMyId = doc.querySelectorAll("tal-shopprice");
        if (elementsWithMyId.length === 0)
        {
            return rawHtmlString;
        }

        // Modify the content of each element
        elementsWithMyId.forEach((element, index) => 
        {
            var productPath = element.getAttribute("product");
            // var product = (productPath => this.props.products.products.find(i => i.path === productPath))[0];
            this.props.products.products.forEach((product, index) =>
            {
                if (productPath === product.path)
                {
                    element.textContent = ""; // remove all children

                    if (product.discountPercentValue > 0)
                    {
                        var surroundingDiv = doc.createElement("div");

                        var newPriceElement = doc.createElement("text");
                        newPriceElement.textContent = product.priceValueWithoutTax.toFixed(2).toString() + " " + this.props.data.data.currency + " + VAT ";
                        surroundingDiv.appendChild(newPriceElement);
                       
                        var small = doc.createElement("small");
                        var oldPriceElement = doc.createElement("s");
                        oldPriceElement.textContent =  product.priceValueWithoutTaxAndDiscounts.toFixed(2).toString() + " " + this.props.data.data.currency;
                        small.appendChild(oldPriceElement);
                        surroundingDiv.appendChild(small);

                        var discountPercent = doc.createElement("div");
                        discountPercent.textContent =  " " + Math.round(product.discountPercentValue.toFixed(0)) + " % OFF";
                        surroundingDiv.appendChild(discountPercent);

                        element.appendChild(surroundingDiv);
                    }
                    else
                    {
                        var surroundingDiv2 = doc.createElement("div");

                        var newPriceElement2 = doc.createElement("text");
                        newPriceElement2.setAttribute("class", "shopNewPrice");
                        newPriceElement2.textContent = product.priceValueWithoutTax.toFixed(2).toString() + " " + this.props.data.data.currency + " + VAT ";
                        surroundingDiv2.appendChild(newPriceElement2);
                        element.appendChild(surroundingDiv2);
                    }
                    /*
                    var price = product.priceValue.toString() + " " + this.props.data.data.currency;
                    var discountInPercent = product.discountPercentValue > 0 
                    ? " " + product.discountPercentValue + "% OFF"
                    : "";*/

                    //element.textContent = priceText; 
                }
            });
        });

        const modifiedHtmlString = doc.documentElement.innerHTML;
        return modifiedHtmlString;
    }

    handleBuyNowButtonClick(e)
    {
        let productName = e.target.getAttribute('productToCheckout');
        if (productName === undefined)
        {
            return false;
        }

        this.buyProductGlobalFastspring(productName);
        return true;
    }

    buyProductGlobalFastspring(productPath)
    {
        if (window.fastspring === undefined)
            return;

        window.fastspring.builder.add(productPath);
        /*
        window.fastspring.builder.push(
            {
                products: [
                {
                    path: productPath,
                    quantity: 1
                }],
                checkout: true
            });
            */

        // window.fastspring.builder.checkout('SessionId');
    }

    buyProductGlobalPaddle(productPath)
    {
        if (global.Paddle === undefined)
            return;

        // var env = global.Paddle.Environment.get();

        global.Paddle.Checkout.open({
        settings: {
            theme: "dark"
        },
        items: [
            {
            priceId: productPath,
            quantity: 1
            },
        ]
        });
    }
}

const PageViewWithHooks =  (props) => ( <PageView {...props} params={useParams()} navigate={useNavigate()} products={useShop()} data={useShop()}/> );
export default PageViewWithHooks;