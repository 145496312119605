import React, { useEffect, useState } from "react"

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';

import { useParams } from 'react-router-dom';

import Editor from "@monaco-editor/react";

import {
    Form,
    Button,
    Container,
    Col,
    Row
} from "react-bootstrap";

import { useForm } from "react-hook-form";

function save(page) {
    let jsonString = JSON.stringify(page);
    fetch(Config.apiUrl + '/page/',
        {
            method: 'POST',
            headers: AuthHeader(),
            body: jsonString
        })
        .then(HandleResponse);
}


async function fetchPage(id) {
    let response = await fetch(Config.apiUrl + `/page/${id}`,
        {
            method: 'GET',
            headers: AuthHeader()
        });
    return await HandleResponse(response);
}

export default function AdminPages() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [pageData, setPageData] = useState([]);
    const params = useParams();

    useEffect(() => {
        const doFetch = async () => {
            const page = await fetchPage(String(params.id)).catch(error => console.log("error: " + error));
            setPageData(page);

            setValue("name", page.name);
            setValue("url", page.url);
            setValue("mainMenuPriority", page.mainMenuPriority);
            setValue("isMainMenu", page.isMainMenu);
            setValue("htmlText", page.htmlText);
        }
        doFetch()
    }, [params.id, setValue])

    return (
        <Container>
            <Form onSubmit={handleSubmit(data => save(pageData))}>
                <div className="newstitle">
                    <div className="well">
                        <h5>Edit page (id: {pageData.id})</h5>
                    </div>
                </div>
                <div className="well">
                    <Row>
                        <Col>
                            <Form.Label htmlFor="name">Name</Form.Label><br />
                            <Form.Control id="name" placeholder="My Page Name" {...register("name", { required: "Name is required" })} type="text" onChange={(e) => setPageData({ ...pageData, name: e.target.value })} />
                            {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}<br />
                        </Col>

                        <Col>
                            <Form.Label htmlFor="url">Url:</Form.Label><br />
                            <Form.Control id="url" placeholder="/test/test" {...register("url", { required: "Url is required" })} type="text" onChange={(e) => setPageData({ ...pageData, url: e.target.value })} />
                            {errors.url && <Form.Text className="text-danger">{errors.url.message}</Form.Text>}<br />
                        </Col>

                        <Col>
                            <Form.Label htmlFor="mainMenuPriority">Main menu order priority:</Form.Label><br />
                            <Form.Control id="mainMenuPriority" placeholder="0" {...register("mainMenuPriority", { required: "Priority is required" })} type="text" onChange={(e) => setPageData({ ...pageData, mainMenuPriority: e.target.value })} />
                            {errors.mainMenuPriority && <Form.Text className="text-danger">{errors.mainMenuPriority.message}</Form.Text>}<br />
                        </Col>

                        <Col>
                            <Form.Label htmlFor="isMainMenu">Main menu:</Form.Label><br />
                            <Form.Check id="isMainMenu" {...register("isMainMenu")} type="checkbox" onChange={(e) => setPageData({ ...pageData, isMainMenu: e.target.checked })} />
                        </Col>

                    </Row>
                    <div>&nbsp;</div>

                    <Form.Label htmlFor="htmlText">HTML page:</Form.Label><br />
                </div>

                <Editor
                    height="400px"
                    language="html"
                    theme="vs-dark"
                    value={pageData.htmlText}
                    onChange={(value) => setPageData({ ...pageData, htmlText: value })}
                />

                {errors.htmlText && <Form.Text className="text-danger">{errors.htmlText.message}</Form.Text>}<br />
                <br />

                <div className="text-center">
                    <Button variant="primary" type="submit">SAVE PAGE</Button>
                </div>
                <div>&nbsp;</div>
                <div dangerouslySetInnerHTML={{ __html: pageData.htmlText }} />
            </Form>
        </Container>);
}