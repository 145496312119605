import React from 'react';

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';

import { Config } from '../../config';

import PersonTable from "./AdminPersonTable"
import { Button } from 'react-bootstrap';


import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

class AdminPersons extends React.Component {
    constructor(props) {
        super(props);
        this.state = { persons: [], loading: true };
        this.newPerson = this.newPerson.bind(this);
    }

    render() {
        let contents = this.renderList();

        return <div className="container">
            <div className="newstitle">
                <div className="well">
                    <h5>Admin Persons</h5>
                </div>
            </div>
            <div className="well">
                {contents}
            </div>
        </div>;
    }

    renderList() {
        return <div>
            <Button onClick={() => this.newPerson(this.props.navigate, { id: 0, name: "name", email: "test@test.com", country: "CH" })}>New Person</Button>
            <p />
            <PersonTable />
        </div>
    }

    newPerson(navigate, person) {
        let jsonString = JSON.stringify(person);
        fetch(Config.apiUrl + '/person/',
            {
                method: 'POST',
                headers: AuthHeader(),
                body: jsonString
            })
            .then(HandleResponse)
            .then(data => { this.editNewPerson(navigate, person.email); });
    }

    editNewPerson(navigate, email) {
        fetch(Config.apiUrl + `/person/byEmail/${email}`,
            {
                method: 'GET',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(data => {
                navigate('/admin/person/' + data.id, { replace: true });
            })
            .catch(this.redirectToLogin);
    }
}

const AdminPersonsWithHook = (props) => (<AdminPersons {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />);
export default AdminPersonsWithHook;